import { Inject, Injectable, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import { ActionMultiplicity, DataDisplayLozengeValue, DataDisplayService, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig, TableConfigColumn, ToastService } from '@unifii/library/common';
import { DataType, Dictionary, ensureError } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { FormSubmittedCondition, UcWorkflow, WorkflowRule, WorkflowState } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { DialogsService } from 'services/dialogs.service';

import { WorkflowActivityLabel, WorkflowEventLabel, WorkflowStateColour, WorkflowStateLabel } from './constants';
import { WorkflowRulesTableDataSource } from './workflow-rules-table-data-source';

@Injectable()
export class WorkflowRulesTableManager implements TableContainerManager<WorkflowRule, FilterValue, FilterEntry>, OnDestroy {

    tableConfig: TableConfig<WorkflowRule>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    addActionConfig = true;
    defaultSort = 'label';
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<WorkflowRule | { item: WorkflowRule; trackBy: keyof WorkflowRule }>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private connection?: Subscription;
    private items: WorkflowRule[];

    constructor(
        private ucWorkflow: UcWorkflow,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(DataDisplayService) private dataDisplayService: DataDisplayService,
        private toastService: ToastService,
        private dialogs: DialogsService,
        @Inject(FilterEntries) entries: FilterEntry[],
    ) {
        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider), null, null);

        const id = `table_workflow_rule`;
        const columns: TableConfigColumn<WorkflowRule>[] = [{
            name: 'label',
            label: 'Title',
            sortable: true,
        }, {
            name: 'event',
            label: 'Event',
            value: (item) => WorkflowEventLabel[item.event.type],
        }, {
            name: 'activities',
            label: 'Activities',
            value: (item) => [...(new Set(item.activities.map((a) => WorkflowActivityLabel[a.type])))].join(','),
        }, {
            name: 'bucket',
            label: 'Form Data Repository',
            value: (item) => (item.condition as FormSubmittedCondition | undefined)?.bucket ?? '',
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified At',
            sortable: true,
            value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
        }, {
            name: 'lastModifiedBy',
            label: 'Last Modified by',
            value: (item) => item.lastModifiedBy.username,
        }, {
            name: 'state',
            label: 'State',
            value: (item) => ({
                label: WorkflowStateLabel[item.state],
                colour: WorkflowStateColour[item.state],
            } satisfies DataDisplayLozengeValue),
            sortable: true,
        }];

        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);

        tableConfig.selectable = 100;
        tableConfig.row = { link: (rule: WorkflowRule) => [rule.id] };
        tableConfig.actions = [{
            label: 'Activate',
            predicate: (row) => row.$implicit.state !== WorkflowState.Active,
            action: (rows) => this.updateWorkflowRules(rows.map((r) => r.$implicit), 'state', WorkflowState.Active),
        }, {
            label: 'Deactivate',
            predicate: (row) => row.$implicit.state !== WorkflowState.Inactive,
            action: (rows) => this.updateWorkflowRules(rows.map((r) => r.$implicit), 'state', WorkflowState.Inactive),
        }, {
            label: 'Dry Run Mode',
            predicate: (row) => row.$implicit.state !== WorkflowState.DryRun,
            action: (rows) => this.updateWorkflowRules(rows.map((r) => r.$implicit), 'state', WorkflowState.DryRun),
        }, {
            label: 'Duplicate',
            multiplicity: ActionMultiplicity.Single,
            action: (row) => this.duplicate(row.$implicit.id),
        }, {
            label: 'Delete',
            multiplicity: ActionMultiplicity.Single,
            action: (row) => this.delete(row.$implicit),
        }];

        this.tableConfig = tableConfig;
    }

    ngOnDestroy() {
        this.connection?.unsubscribe();
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        let params: Dictionary<any> | undefined;

        if (inputs != null) {
            params = this.inputManager.serializeInputs(inputs);
        }

        const dataSource = new WorkflowRulesTableDataSource(this.ucWorkflow, params);

        this.connection?.unsubscribe();
        this.items = [];
        this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items.data ?? [])));

        return dataSource;
    }

    getNextItem(id?: string): WorkflowRule | undefined {
        const itemIndex = this.items.findIndex((item) => item.id === id);

        if (itemIndex < 0) {
            return;
        }

        return this.items[itemIndex + 1];
    }

    private duplicate(id: string) {
        void this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
    }

    private async delete(rule: WorkflowRule) {
        try {

            if (!await this.dialogs.confirmDelete()) {
                return;
            }

            await this.ucWorkflow.deleteRule(rule.id);
            this.reload.next();
            this.toastService.success('Workflow rule deleted');
        } catch (e) {
            this.toastService.error(ensureError(e, 'Could not delete workflow rule').message);
        }
    }

    private async updateWorkflowRules(workflowRules: WorkflowRule[], key: keyof WorkflowRule, value: any): Promise<void> {
        try {
            for (const rule of workflowRules) {
                rule[key] = value;
                const updatedRule = await this.ucWorkflow.updateRule(rule);

                this.updateItem.next(updatedRule);
            }

            this.toastService.success('Workflow rule/s updated');
        } catch (e) {
            this.toastService.error('Failed to update workflow rule/s');
        }
    }

}

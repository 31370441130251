import { Injectable, inject } from '@angular/core';
import { DataDisplayLozengeValue, DataDisplayService, TableAction, TableConfigColumn, ToastService } from '@unifii/library/common';
import { CompoundType, DataType, DefinitionPublishState } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { CompoundInfo, SystemRole, UcLanguage } from 'client';
import { TranslationStateHelper } from 'helpers/translation-states-helper';
import { PublishedStatusColour } from 'pipes';
import { ContextService } from 'services/context.service';

import { LanguageCollectionStore } from './language-collection-store';

@Injectable()
export class LanguageColumnFactory {

    private dataDisplayService = inject(DataDisplayService);
    private toastService = inject(ToastService);
    private context = inject(ContextService);
    private ucLanguage = inject(UcLanguage);
    private publishedStatusColourPipe = inject(PublishedStatusColour);
    private store = inject(LanguageCollectionStore, { optional: true });

    create(): TableConfigColumn<CompoundInfo>[] {
        return [{
            name: 'name',
            label: 'Name',
            sortable: true,
        }, {
            name: 'sourcePublishState',
            label: 'Source State',
            value: (item: any) => ({
                label: item.sourcePublishState, colour: this.publishedStatusColourPipe.transform(item.sourcePublishState),
            } satisfies DataDisplayLozengeValue),
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified',
            sortable: true,
            value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
        }, {
            name: 'lastPublishedAt',
            label: 'Last Published',
            sortable: true,
            value: (item) => this.dataDisplayService.displayAsString(item.lastPublishedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
        }, {
            name: 'publishState',
            label: 'Translation State',
            value: (item) => {
                if (item.publishState as DefinitionPublishState | undefined) {
                    return {
                        label: item.publishState, colour: this.publishedStatusColourPipe.transform(item.publishState),
                    } satisfies DataDisplayLozengeValue;
                } else {
                    return {
                        label: 'Not Translated', colour: 'disabled',
                    } satisfies DataDisplayLozengeValue;
                }
            },
        }];
    }

    createActions(type: CompoundType, callback: Subject<void>): TableAction<CompoundInfo>[] {
        return [{
            label: 'Approve',
            action: (rows) => {
                const items = rows.map((row) => row.$implicit);

                void this.approve(items, type, callback);
            },
            predicate: (row) => this.context.checkRoles(SystemRole.Translator) && TranslationStateHelper.canApprove(row.$implicit),
        },
        {
            label: 'Unapprove',
            action: (rows) => {
                const items = rows.map((row) => row.$implicit);

                void this.unapprove(items, type, callback);
            },
            predicate: (row) => this.context.checkRoles(SystemRole.Translator) && TranslationStateHelper.canUnapprove(row.$implicit),
        }];

    }

    private async approve(items: CompoundInfo[], type: CompoundType, callback: Subject<void>) {
        const label = type === CompoundType.View ? `View/s` : `Collection/s`;

        try {
            for (const { id } of items) {
                if (type === CompoundType.View) {
                    await this.ucLanguage.approveView(+id);
                } else {
                    await this.store?.ucCollectionTranslation.approve(+id);
                }
            }
            this.toastService.success(`${label} approved`);
        } catch (e) {
            this.toastService.error('Failed to approve');
        } finally {
            callback.next();
        }
    }

    private async unapprove(items: CompoundInfo[], type: CompoundType, callback: Subject<void>) {
        const label = type === CompoundType.View ? `View/s` : `Collection/s`;

        try {
            for (const { id } of items) {
                if (type === CompoundType.View) {
                    await this.ucLanguage.unapproveView(+id);
                } else {
                    await this.store?.ucCollectionTranslation.unapprove(+id);
                }
            }
            this.toastService.success(`${label} unapproved`);
        } catch (e) {
            this.toastService.error('Failed to unapprove');
        } finally {
            callback.next();
        }
    }

}

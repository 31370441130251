import { Injectable, inject } from '@angular/core';
import { Client, ClientDeleteOptions, ClientGetOptions, ClientHeadOptions, ClientPatchOptions, ClientPostOptions, ClientPutOptions, Company, FileType, Language, PasswordDetails, UserInfo, getEtag, mergeParams } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { Config } from 'app-config';
import { IntegrationProvider, IntegrationProviderInfo, MyAccount, ProjectTranslations, TenantSettings, UcProjectInfo } from 'client';
import { DefaultPaginationParams } from 'constant';

import { TokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class UcClient {

    inProgress = new Subject<boolean>();

    private config = inject(Config);
    private client = inject(Client);
    private tokenService = inject(TokenService);

    constructor() {
        this.client.start = () => this.inProgress.next(true);
        this.client.end = () => this.inProgress.next(false);
    }

    authenticate(username: string, password: string): Promise<void> {
        return this.client.authenticate(username, password);
    }

    deAuthenticate() {
        this.tokenService.clear();
    }

    getMyAccount(options?: ClientGetOptions): Promise<MyAccount> {
        return this.get(this.buildUrl(['my-account']), options) as Promise<MyAccount>;
    }

    updateMyPassword(passwordDetails: PasswordDetails, options?: ClientPatchOptions): Promise<MyAccount> {
        return this.patch(this.buildUrl(['my-account']), passwordDetails, options) as Promise<MyAccount>;
    }

    getMe(options?: ClientGetOptions): Promise<UserInfo> {
        return this.get(this.buildUrl(['me']), options) as Promise<UserInfo>;
    }

    getSettings(options?: ClientGetOptions): Promise<TenantSettings> {
        return this.get(this.buildUrl(['settings']), { ...options, anonymous: true }) as Promise<TenantSettings>;
    }

    getFileTypes(options?: ClientGetOptions): Promise<FileType[]> {
        return this.get(this.buildUrl(['file-types']), options) as Promise<FileType[]>;
    }

    getProjects(options?: ClientGetOptions): Promise<UcProjectInfo[]> {
        return this.get(this.buildUrl(['projects']), options) as Promise<UcProjectInfo[]>;
    }

    addProject(name: string, options?: ClientPostOptions): Promise<UcProjectInfo> {
        return this.post(this.buildUrl(['projects']), { ...options, body: { name } }) as Promise<UcProjectInfo>;
    }

    getLanguages(options?: ClientGetOptions): Promise<Language[]> {
        return this.get(this.buildUrl(['languages']), options) as Promise<Language[]>;
    }

    getProjectsTranslations(options?: ClientGetOptions): Promise<ProjectTranslations[]> {
        return this.get(this.buildUrl(['projects/translations']), options) as Promise<ProjectTranslations[]>;
    }

    updateSettings(settings: TenantSettings, options?: ClientPutOptions): Promise<TenantSettings> {
        return this.put(this.buildUrl(['settings']), settings, options) as Promise<TenantSettings>;
    }

    getClaims(q?: string, options?: ClientGetOptions): Promise<string[]> {
        return this.get(this.buildUrl(['claims']), { ...options, params: mergeParams(options?.params, { q }) }) as Promise<string[]>;
    }

    getAvailableIntegrations(q?: string, options?: ClientGetOptions): Promise<IntegrationProviderInfo[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q });

        return this.get(this.buildUrl(['available-integrations']), { ...options, params }) as Promise<IntegrationProviderInfo[]>;
    }

    getAvailableIntegration(id: string, options?: ClientGetOptions): Promise<IntegrationProvider> {
        return this.get(this.buildUrl(['available-integrations', id]), options) as Promise<IntegrationProvider>;
    }

    testAvailableIntegration(id: string, data: any, options?: ClientPostOptions): Promise<boolean> {
        return this.post(this.buildUrl(['available-integrations', id, 'connection-test']), { ...options, body: data }) as Promise<boolean>;
    }

    getCompanies(q?: string, options?: ClientGetOptions): Promise<Company[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q });

        return this.get(this.buildUrl(['resources', 'companies']), { ...options, params }) as Promise<Company[]>;
    }

    getCompany(id: string): Promise<Company> {
        return this.get(this.buildUrl(['resources', 'companies', id])) as Promise<Company>;
    }

    async getRevisionHeader(url: string) {
        const headers = await this.client.head(url, { skipExecutionEmit: true });

        return getEtag(headers)?.etag;
    }

    /**
     * SDK Extensions
     */

    get(url: string, options?: ClientGetOptions): Promise<any> {
        return this.client.get(url, options);
    }

    put(url: string, body: any, options?: ClientPutOptions): Promise<any> {
        return this.client.put(url, body, options);
    }

    post(url: string, options?: ClientPostOptions): Promise<any> {
        return this.client.post(url, options);
    }

    patch(url: string, body: any, options?: ClientPatchOptions): Promise<any> {
        return this.client.patch(url, body, options);
    }

    delete(url: string, options?: ClientDeleteOptions): Promise<any> {
        return this.client.delete(url, options);
    }

    head(url: string, options?: ClientHeadOptions): Promise<Headers> {
        return this.client.head(url, options);
    }

    buildUrl(parts: any[], addApi = true): string {

        parts = parts.map((p) => encodeURIComponent(p));

        if (addApi) {
            parts.unshift('api');
        }
        parts.unshift(this.config.baseUrl);

        return parts.join('/');
    }

}

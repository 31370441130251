import { Component, Input } from '@angular/core';
import { DataDisplayListItem, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNodeVariation } from '@unifii/sdk';

import { FieldReferenceHelper } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';

import { StructureNodeVariationControlKeys } from '../structure-control-keys';
import { StructureEditorService } from '../structure-editor.service';
import { StructureFormCtrl } from '../structure-form-ctrl';
import { StructureFunctions } from '../structure-functions';

@Component({
    selector: 'uc-node-variations',
    templateUrl: './node-variations.html',
})
export class NodeVariationsComponent {

    @Input() control: UfControlArray;

    readonly variationControlKeys = StructureNodeVariationControlKeys;

    constructor(
        private service: StructureEditorService,
        private sfb: StructureFormCtrl,
        private dialogs: DialogsService,
    ) { }

    async add() {

        // Select an HomePage node
        const node = await this.service.chooseHomePage();

        if (!node) {
            return;
        }

        delete node.children;

        const variationControl = this.sfb.buildNodeVariationControl(node);

        this.control.push(variationControl);
    }

    async remove(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

    getIcon(variation: UfControlGroup): string {
        return FieldReferenceHelper.getFieldReference(variation.value, CompoundType.Structure).icon;
    }

    getRoles(variation: UfControlGroup): string[] {
        return (variation.getRawValue() as StructureNodeVariation).roles ?? [];
    }

    getIsPublished(variation: UfControlGroup): boolean {
        return StructureFunctions.isNodeContentPublished(variation.getRawValue() as StructureNodeVariation);
    }

    getVariationRolesDescriptionItem(control: UfControlGroup): DataDisplayListItem[] | undefined {

        const roles = this.getRoles(control);

        if (!roles.length) {
            return;
        }

        return [{ term: 'Roles', data: roles.join(', ') }];
    }

}

<ng-container *ngIf="form && !error">

    <div class="uf-app-bar default flat transparent pad-sides">
        <uc-builder-header>
            <div header-buttons>
                <a (click)="openPreview()" header-buttons class="uf-button small">Preview</a>
                <uf-checkbox [value]="status.enableDependentsValidation"
                    (valueChange)="service.toggleDependentsValidation($event)" />
            </div>
        </uc-builder-header>
    </div>

    <div [class.can-edit]="!form.disabled" class="builder-main">

        <uf-panel *ngIf="!form.disabled" class="scrollable">
            <uc-item-picker *ngIf="bucket" [groups]="status.itemPickerGroups"
                (delete)="service.deleteTemplateField($event)" />
        </uf-panel>

        <uf-panel class="dropzone uf-box flat">

            <uc-form-field (expandAll)="toggleExpanders($event, fieldList)" class="fields-header" />

            <uc-drag-list [parent]="fields" [items]="fields.controls" [canDrop]="service.canDrop"
                [convertAdded]="service.addConverter" [entries]="fields.controls" [current]="status.selected"
                [filter]="filterFieldControls" (next)="selectField($event)" (moved)="service.fieldMoved($event)"
                (insert)="service.fieldAdded($event)" childrenProperty="fields" keySelect
                class="pad-sm-left pad-xs-top">

                <div *ngIf="!fields.length" class="pad col center-all">
                    <img src="/assets/svg/icon-structure.svg" alt="" />
                    <p class="small text-center">
                        Your form is empty, drag an item from the left to start creating it!
                    </p>
                </div>
                <div #fieldList>
                    <uc-form-field *ngFor="let field of fields.controls | asUfControlsGroups" [control]="field"
                        [dragDisabled]="field.disabled" [nestable]="!!status.containersByFieldType[field.value?.type]"
                        dragItem class="gap-sm-bottom" />
                </div>
            </uc-drag-list>

        </uf-panel>

        <uf-panel class=" scrollable">
            <uc-form-metadata *ngIf="!status.selected" [control]="form" />
            <uc-form-field-configuration *ngIf="status.selected" [control]="status.selected" />
        </uf-panel>

    </div>

</ng-container>

<uc-error-message [error]="error" />